<template>
  <nav aria-label="Page navigation">
    <!-- v-if="shouldRender" -->
    <ul class="pagination justify-content-end">
      <li class="page-item" :class="{ disabled: currentPage < 2 }">
        <router-link
          class="page-link page-link-prev"
          :to="getPageUrl(currentPage - 1)"
        >
          <span aria-hidden="true" style="font-size: 2rem">
            <!-- <i class="icon-long-arrow-left"></i> -->
            «
          </span>
        </router-link>
      </li>

      <template v-if="pagesToBeShown.length">
        <li
          class="page-item"
          v-for="page in pagesToBeShown"
          :key="`page-${page}`"
        >
          <router-link
            class="page-link"
            exact-active-class="active"
            :to="getPageUrl(page)"
            >{{ page }}</router-link
          >
        </li>
      </template>

      <li class="page-item-total pl-3" v-if="lastPage > 3">
        of {{ lastPage }}
      </li>

      <li class="page-item" :class="{ disabled: currentPage === lastPage }">
        <router-link
          class="page-link page-link-next"
          :to="getPageUrl(currentPage + 1)"
        >
          <span aria-hidden="true" style="font-size: 2rem">
            <!-- <i class="icon-long-arrow-right"></i> -->
            »
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    perPage: Number,
    total: Number,
  },
  computed: {
    shouldRender: function () {
      return this.total > this.perPage;
    },
    currentPage: function () {
      return parseInt(this.$route.query.page ? this.$route.query.page : 1);
    },
    lastPage: function () {
      return (
        parseInt(this.total / this.perPage) +
        (this.total % this.perPage ? 1 : 0)
      );
    },
    startIndex: function () {
      if (!this.currentPage % this.perPage) {
        return this.currentPage;
      }
      return this.perPage * parseInt(this.currentPage / this.perPage);
    },
    pageCount() {
      return (
        Math.floor(this.total / this.perPage) +
        (0 < this.total % this.perPage ? 1 : 0)
      );
    },
    pagesToBeShown: function () {
      let pageNumbers = [];

      for (let i = -1; i < 2 && this.pageCount >= 3; i++) {
        if (1 < this.currentPage && this.currentPage < this.pageCount)
          pageNumbers.push(this.currentPage + i);
        if (1 === this.currentPage) pageNumbers.push(this.currentPage + i + 1);
        if (this.currentPage === this.pageCount)
          pageNumbers.push(this.currentPage + i - 1);
      }

      for (let i = 0; i < this.pageCount && this.pageCount < 3; i++) {
        pageNumbers.push(i + 1);
      }
      return pageNumbers;
    },
  },
  methods: {
    getPageUrl: function (page) {
      let originQuery = {};
      for (let key in this.$route.query) {
        if (key !== "page") {
          originQuery[key] = this.$route.query[key];
        }
      }
      if (page > 1) {
        originQuery.page = page;
      }

      return {
        path: this.$route.path,
        query: originQuery,
      };
    },
  },
};
</script>
