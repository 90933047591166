<template>
  <div class="sidebar-shop" :class="isSidebar ? 'sidebar-filter' : 'sidebar'">
    <div :class="{ 'sidebar-filter-wrapper': isSidebar }">
      <div class="widget widget-clean">
        <label>Filters:</label>
        <a href="#" class="sidebar-filter-clear" @click.prevent="cleanAll"
          >Clean All</a
        >
      </div>
      <p class="text-danger" v-if="aggregation && aggregation.length === 0">
        No data found
      </p>
      <div
        class="widget widget-collapsible"
        v-for="(agg, index) in aggregation"
        :key="index"
      >
        <h3 class="widget-title mb-2">
          <a
            :href="`#widget-$-${index}`"
            :class="{ collapsed: !toggleStates[index] }"
            @click.prevent="toggleSlide(index)"
            >{{ agg.label }}</a
          >
        </h3>

        <vue-slide-toggle
          :open="toggleStates[index]"
          class="show"
          :duration="200"
        >
          <div class="widget-body pt-0">
            <div class="filter-items filter-items-count">
              <div
                class="filter-item"
                v-for="(opt, index) in agg.options"
                :key="index"
              >
                <!-- <router-link :to="{ path: $route.path, query: { category: category.slug } }"
                                    :class="{ active: categorySelected(category) }">{{ category.name }}</router-link> -->
                <router-link to="">{{ opt.label }}</router-link>
                <span class="item-count">{{ opt.count }}</span>
              </div>
            </div>
          </div>
        </vue-slide-toggle>
      </div>

      <!-- Filters -->

      <!-- <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a href="#widget-1" :class="{ collapsed: !toggleStates[0] }" @click.prevent="toggleSlide(0)">{{
        $t("products") }}</a>
                </h3>

                <vue-slide-toggle :open="toggleStates[0]" class="show" :duration="200">
                    <div class="widget-body pt-0">
                        <div class="filter-items filter-items-count">
                            <div class="filter-item" v-for="(category, index) in filterData.categories" :key="index">
                                <router-link :to="{ path: $route.path, query: { category: category.slug } }"
                                    :class="{ active: categorySelected(category) }">{{ category.name }}</router-link>
                                <span class="item-count">{{ category.count }}</span>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a href="#widget-1" :class="{ collapsed: !toggleStates[1] }" @click.prevent="toggleSlide(1)">{{
        $t('color') }}</a>
                </h3>

                <vue-slide-toggle :open="toggleStates[1]" class="show" :duration="200">
                    <div class="widget-body pt-0">
                        <div class="filter-colors">
                            <router-link :to="getColorUrl(item)" :style="{ 'background-color': item.color }"
                                v-for="(item, index) in filterData.colors" :key="index"
                                :class="{ selected: colorSelected(item) }">
                                <span class="sr-only">{{ item.color_name }}</span>
                            </router-link>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a href="#widget-2" :class="{ collapsed: !toggleStates[2] }" @click.prevent="toggleSlide(2)">{{
        $t("marks") }}</a>
                </h3>

                <vue-slide-toggle :open="toggleStates[2]" class="show" :duration="200">
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div class="filter-item" v-for="(item, index) in filterData.brands" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :id="'brand-' + index"
                                        @click="setBrandFilter(item)" :checked="brandChecked(item)" />
                                    <label class="custom-control-label" :for="'brand-' + index">{{ item.brand }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div>

            <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a href="#widget-3" :class="{ collapsed: !toggleStates[3] }" @click.prevent="toggleSlide(3)">{{
        $t("size") }}</a>
                </h3>

                <vue-slide-toggle :open="toggleStates[3]" class="show" :duration="200">
                    <div class="widget-body pt-0">
                        <div class="filter-items">
                            <div class="filter-item" v-for="(item, index) in filterData.sizes" :key="index">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :id="'size-' + index"
                                        @click="setSizeFilter(item)" :checked="sizeChecked(item)" />
                                    <label class="custom-control-label" :for="'size-' + index">{{ item.slug }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div> -->

      <!-- Filters -->

      <!-- <div class="widget widget-collapsible">
                <h3 class="widget-title mb-2">
                    <a
                        href="#widget-5"
                        :class="{collapsed: !toggleStates[4]}"
                        @click.prevent="toggleSlide(4)"
                    >Price</a>
                </h3>

                <vue-slide-toggle :open="toggleStates[4]" class="show" :duration="200">
                    <div class="widget-body pt-0">
                        <div class="filter-price">
                            <div class="filter-price-text d-flex justify-content-between">
                                <span>
                                    Price Range:
                                    <span id="filter-price-range">{{ priceRangeText }}</span>
                                </span>
                                <router-link :to="priceFilterRoute" class="pr-2">Filter</router-link>
                            </div>

                            <vue-nouislider
                                :config="priceSliderConfig"
                                :values="priceValues"
                                id="price-slider"
                                v-if="loaded"
                            ></vue-nouislider>
                        </div>
                    </div>
                </vue-slide-toggle>
            </div> -->
    </div>
  </div>
</template>

<script>
import { VueSlideToggle } from "vue-slide-toggle";
import { shopData } from "@/esf_weert_mobilesupplies/utilities/data";

export default {
  components: {
    VueSlideToggle,
  },
  props: {
    isSidebar: Boolean,
    aggregation: Array,
  },
  data: function () {
    return {
      loaded: true,
      priceValues: [0, 1000],
      priceSliderConfig: {
        connect: true,
        step: 50,
        margin: 100,
        range: {
          min: 0,
          max: 1000,
        },
      },
      toggleStates: [true, true, true, true],
      filterData: shopData,
    };
  },
  computed: {
    priceRangeText: function () {
      return (
        "$" +
        parseInt(this.priceValues[0]) +
        " — $" +
        parseInt(this.priceValues[1])
      );
    },
    priceFilterRoute: function () {
      let query = {};
      if (this.$route.query.page) {
        for (let key in this.$route.query) {
          if (key !== "page") {
            query[key] = this.$route.query[key];
          }
        }
      } else {
        query = { ...this.$route.query };
      }

      return {
        path: this.$route.path,
        query: {
          ...query,
          minPrice: this.priceValues[0],
          maxPrice: this.priceValues[1],
        },
      };
    },
  },
  created: function () {
    if (typeof window !== "undefined") {
      document.querySelector("body").classList.remove("sidebar-filter-active");
      if (this.$route.query.minPrice && this.$route.query.maxPrice) {
        this.loaded = false;
        this.priceValues = [
          this.$route.query.minPrice,
          this.$route.query.maxPrice,
        ];
        this.$nextTick(function () {
          this.loaded = true;
        });
      } else {
        this.loaded = false;
        this.$nextTick(function () {
          this.loaded = true;
        });
      }
    }
  },
  methods: {
    cleanAll: function () {
      this.loaded = false;
      this.priceValues = [0, 1000];
      this.$nextTick(function () {
        this.orderBy = "default";
        this.loaded = true;
        this.$router.push(this.$route.path);
      });
    },
    toggleSlide: function (index) {
      this.toggleStates = this.toggleStates.reduce((acc, cur, id) => {
        if (id == index) return [...acc, !cur];
        else return [...acc, cur];
      }, []);
    },
    sizeChecked: function (item) {
      return (
        this.$route.query.size &&
        this.$route.query.size.split(",").includes(item.slug)
      );
    },
    brandChecked: function (item) {
      return (
        this.$route.query.brand &&
        this.$route.query.brand.split(",").includes(item.slug)
      );
    },
    categorySelected: function (item) {
      return (
        this.$route.query.category && this.$route.query.category == item.slug
      );
    },
    colorSelected: function (item) {
      return (
        this.$route.query.color &&
        this.$route.query.color.split(",").includes(item.color_name)
      );
    },
    setSizeFilter: function (item) {
      let query = {};
      if (this.$route.query.page) {
        for (let key in this.$route.query) {
          if (key !== "page") {
            query[key] = this.$route.query[key];
          }
        }
      } else {
        query = { ...this.$route.query };
      }

      if (!this.$route.query.size) {
        query = {
          ...query,
          size: item.slug,
        };
      } else if (this.$route.query.size.split(",").includes(item.slug)) {
        query = {
          ...query,
          size: query.size
            .split(",")
            .filter((slug) => slug !== item.slug)
            .join(","),
        };
      } else {
        query = {
          ...query,
          size: [...query.size.split(","), item.slug].join(","),
        };
      }

      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
    setBrandFilter: function (item) {
      let query = {};
      if (this.$route.query.page) {
        for (let key in this.$route.query) {
          if (key !== "page") {
            query[key] = this.$route.query[key];
          }
        }
      } else {
        query = { ...this.$route.query };
      }

      if (!this.$route.query.brand) {
        query = {
          ...query,
          brand: item.slug,
        };
      } else if (this.$route.query.brand.split(",").includes(item.slug)) {
        query = {
          ...query,
          brand: query.brand
            .split(",")
            .filter((slug) => slug !== item.slug)
            .join(","),
        };
      } else {
        query = {
          ...query,
          brand: [...query.brand.split(","), item.slug].join(","),
        };
      }

      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
    getColorUrl: function (item) {
      let query = {};

      if (!this.$route.query.color) {
        query = {
          ...this.$route.query,
          color: item.color_name,
        };
      } else if (this.$route.query.color.split(",").includes(item.color_name)) {
        query = {
          ...this.$route.query,
          color: this.$route.query.color
            .split(",")
            .filter((slug) => slug !== item.color_name)
            .join(","),
        };
      } else {
        query = {
          ...this.$route.query,
          color: [...this.$route.query.color.split(","), item.color_name].join(
            ","
          ),
        };
      }

      let temp = {};
      if (query.page) {
        for (let key in query) {
          if (key !== "page") {
            temp[key] = query[key];
          }
        }
      } else {
        temp = { ...query };
      }

      return {
        path: this.$route.path,
        query: temp,
      };
    },
  },
};
</script>
